<template>
  <div class="drag-panel">
    <div class="drag-panel-header">
      <span class="header-title">About</span>
    </div>
    <div class="drag-panel-content">
    </div>
    <div class="drag-panel-footer"></div>
  </div>
</template>

<script>

  export default {
    name: 'AboutPanel',
    components: {
    }
  }
</script>

<style scoped>
  .drag-panel {
    background-color: olive;
    height: 100%;
    max-height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .drag-panel-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 25px;
    background-color: lightgray;
  }

  .header-title {
    position: fixed;
    top: 5px;
    left: 5px;
    //right: 0;
    height: 25px;
    font-size: 12px;
    font-weight: 500; /* 400 is normal; 700 is bold */
  }

  .drag-panel-content {
    position: fixed;
    top: 25px;
    bottom: 30px;
    left: 0;
    right: 0;
    background-color: lightblue;
    //overflow: auto;
  }

  .drag-panel-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background-color: lightgray;
  }

  p {
    font-size: 12px;
  }
</style>
